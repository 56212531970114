<template>
  <Title :text="t(`t1`)" />
  <div class="grid-column grid-column_span_5">
    <Promo
      class="promo_full_width"
      :src-pic="require(`@/assets/cover/edutainment.jpg`)"
      :src-pic-mobile="require(`@/assets/cover/m/edutainment.jpg`)"
    />
  </div>

  <div class="grid-column">
    <Share :title="t(`t1`)" />
  </div>
  <div class="grid-column grid-column_span_4 grid-column_row_2">
    <div class="h2-1" v-html="t(`t2`)"></div>
  </div>

  <div class="grid-column"></div>
  <div class="grid-column"></div>
  <div class="grid-column"></div>
  <div class="grid-column"></div>
  <div class="grid-column">
    <div class="content-pattern content-pattern_type_1"></div>
  </div>

  <div v-if="isMobile" class="grid-line"></div>

  <News />
</template>

<script>
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";

import Title from "@/components/Title.vue";
import Promo from "@/components/Promo.vue";
import Share from "@/components/Share.vue";
import News from "@/components/News.vue";

export default defineComponent({
  name: "Edutainment",
  components: {
    Title,
    Promo,
    Share,
    News,
  },
  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "local",
    });

    return { t };
  },
});
</script>

<style lang="scss" scoped></style>

<i18n>
{
  "en": {
    "t1": "Edutainment",
    "t2": "The Office of Knowledge and Innovation focuses on the value of spreading innovation and education through the best Edutainment practices and formats, supporting the main Qatari and global events and producing its own educational public formats: Initiatives where education meets entertainment to learn through interesting experience.",
  },
  "ru": {
    "t1": "Эдьютейнмент",
    "t2": "OKI придает большое значение распространению инноваций и образовательных проектов на основе передовых практик и моделей эдьютейнмента, поддерживая основные международные мероприятия и проекты, организуемые Катаром, и создает собственные образовательные продукты в открытом формате: проекты сочетают в себе элементы игры и обучения.",
  },
  "ar": {
    "t1": "التعليم بالتسلية",
    "t2": "يولي مكتب المعرفة والابتكار أهمية كبيرة لنشر الابتكارات والمشاريع التعليمية القائمة على أفضل الممارسات والنماذج التعليمية والترفيهية، ودعم الأحداث والمشاريع الدولية الكبرى التي تنظمها قطر، وتخلق منتجاتها التعليمية الخاصة في شكل مفتوح: تجمع المشاريع بين عناصر اللعب والتعلم.",
  }
}
</i18n>
